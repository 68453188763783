<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useBlackFridayModalWindow } from '~/composables/black-friday-modal-window'
import Page from '~/enums/page'

const model = defineModel<boolean>()

const imagePath = '/images/banners/88_mw.jpg'
const categoryPath = `${Page.Categories}chyornaya-pyatnitsa-77/`
const title = 'Влетайте в\xA0последний вагон со\xA0скидками!'

const router = useRouter()

const { makeBlackFridayModalWindowClosed } = useBlackFridayModalWindow()

function close (): void {
  model.value = false
}

function closed (): void {
  makeBlackFridayModalWindowClosed()
}

async function openCategory (): Promise<void> {
  await router.push(categoryPath)
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large" @closed="closed">
    <template #title>
      {{ title }}
    </template>

    <p>Когда, если не сейчас? Не пропустите последний день Черной пятницы, чтобы потом не жалеть об этом целый год и порадовать себя и близких полезными продуктами.</p>

    <div class="relative h-0 cursor-pointer pb-[56.25%]" @click="openCategory(); close()">
      <img
        v-lazy="imagePath"
        :alt="title"
        class="absolute size-full min-h-full rounded-xl object-contain"
        loading="lazy"
      >
    </div>

    <template #action>
      <BasicButton color="green" title="Не упущу момент!" @click="openCategory(); close()" />
    </template>
  </CustomModalWindow>
</template>
