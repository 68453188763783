import { useModal } from 'vue-final-modal'

import BlackFriday from '~/components/modal-windows/BlackFriday.vue'

const localStorageKey = 'blackFriday3ModalWindowClosedAt'
const delayBeforeShowingInSeconds = 30

export function useBlackFridayModalWindow () {
  function getBlackFridayModalWindowClosedAt (): number | null {
    const value = localStorage.getItem(localStorageKey)

    return value === null ? null : Number(value)
  }

  function makeBlackFridayModalWindowClosed (): void {
    localStorage.setItem(localStorageKey, JSON.stringify(Date.now()))
  }

  function needToShowBlackFridayModalWindow (): boolean {
    return getBlackFridayModalWindowClosedAt() === null
  }

  function showBlackFridayModalWindowIfNeeded (): void {
    const currenDate = new Date();
    const startDate = new Date('2024-11-30');
    const lastDate = new Date('2024-12-02');

    if (currenDate >= lastDate || currenDate < startDate || !needToShowBlackFridayModalWindow()) {
      return
    }

    setTimeout(
      async function (): Promise<void> {
        if (!needToShowBlackFridayModalWindow()) {
          return
        }

        await useModal({ component: BlackFriday }).open()
      },
      1000 * delayBeforeShowingInSeconds
    )
  }

  return {
    getBlackFridayModalWindowClosedAt,
    makeBlackFridayModalWindowClosed,
    showBlackFridayModalWindowIfNeeded
  }
}
